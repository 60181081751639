import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import siteLogo from "./site_logo.png";
import FeatherIcon from "feather-icons-react";

function Layout({ children }) {
  useEffect(() => {
    // const gtmScript = document.createElement("script");
    // gtmScript.async = true;
    // gtmScript.src = "https://www.googletagmanager.com/gtm.js?id=GTM-T7ZL24QT";
    // document.head.appendChild(gtmScript);
    // // Load the Google Tag Manager script
    // const gtagScript = document.createElement("script");
    // gtagScript.async = true;
    // gtagScript.src =
    //   "https://www.googletagmanager.com/gtag/js?id=AW-11556258873";
    // document.head.appendChild(gtagScript);

    // // Initialize Google Analytics
    // gtagScript.onload = () => {
    //   window.dataLayer = window.dataLayer || [];
    //   function gtag() {
    //     window.dataLayer.push(arguments);
    //   }
    //   window.gtag = gtag;
    //   gtag("js", new Date());
    //   gtag("config", "AW-11556258873");
    //   gtag("config", "GTM-T7ZL24QT"); // Add GTM tracking
    // };

    // Add JSON-LD schema
    const jsonLdScript = document.createElement("script");
    jsonLdScript.type = "application/ld+json";
    jsonLdScript.text = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "WebSite",
      name: "Kronos",
      url: "https://kronosapp.se/",
      description:
        "Köp och sälj klockor tryggt via Kronos. Sveriges nya marknadsplats för att sälja och köpa klockor",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": "https://kronosapp.se/",
      },
      publisher: {
        "@type": "Organization",
        name: "Kronos",
        url: "https://kronosapp.se/",
        logo: {
          "@type": "ImageObject",
          url: "https://kronosapp.se/static/media/site_logo.4014536f41ed5684e1ee.png",
          width: 300,
          height: 100,
        },
        contactPoint: {
          "@type": "ContactPoint",
          email: "info@kronosapp.se",
          contactType: "customer service",
          areaServed: "SE",
          availableLanguage: "Swedish",
        },
      },
    });

    document.head.appendChild(jsonLdScript);
  }, []);

  return (
    <>
      <header className="absolute inset-x-0 top-0 z-50 bg-white">
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
        <nav className="flex items-center justify-between p-6 lg:px-8">
          <div className="flex lg:flex-1">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Krono</span>
              <img
                alt="Krono Logo"
                src={siteLogo}
                className="h-12 w-auto rounded-md"
              />
            </Link>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {/* <Link
              to="/krono-avtal"
              className="text-sm font-semibold text-gray-900 font-poppins"
            >
              Krono Avtal
            </Link> */}
            <Link
              to="/integritetspolicy"
              className="text-sm font-semibold text-gray-900 font-poppins"
            >
              Integritetspolicy
            </Link>
            <Link
              to="/anvandarvillkor"
              className="text-sm font-semibold text-gray-900 font-poppins"
            >
              Användarvillkor
            </Link>
          </div>
        </nav>
      </header>
      <main className="mt-24">{children}</main>
      <footer className="bg-gray-50 py-4 text-center text-gray-600 font-poppins">
        <p className="my-2">
          Kontakt:{" "}
          <a href="mailto:info@kronosapp.se" className="underline">
            info@kronosapp.se
          </a>
        </p>
        <div className="flex flex-row justify-center align-middle items-center my-4 gap-4">
          <a href="https://www.facebook.com/profile.php?id=61573060364645">
            <FeatherIcon icon="facebook" size={35} color="black" />
          </a>
          <a href="https://www.instagram.com/kronosappen/">
            <FeatherIcon icon="instagram" size={35} color="black" />
          </a>
        </div>
        © 2024 Kronos - Utvecklat av App Labs Sweden AB
      </footer>
    </>
  );
}

export default Layout;
