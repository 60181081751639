import React, { useState } from "react";
import start from "../src/start-new.webp";
import applestore from "../src/get_from_app_store_se.svg";
import login from "../src/login-new.webp";
import googleplay from "../src/google-play.png";
import chat from "../src/chat-2.png";
import certified from "../src/kornos-certified.png";
import kronogram from "../src/kronos.png";
import { Dialog, DialogPanel } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import FeatherIcon from "feather-icons-react";
import gyrate from "../src/gyrate.webp";
import cert from "../src/kronos-cert-black.webp";

const navigation = [
  { name: "Integritetspolicy", href: "/integritetspolicy" },
  { name: "Användarvillkor", href: "/anvandarvillkor" },
];

function StartPage({ siteLogo }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <>
      <div className="bg-white">
        <header className="absolute inset-x-0 top-0 z-50 bg-white">
          <nav
            class="flex items-center justify-between p-6 lg:px-8"
            aria-label="Global"
          >
            <div class="flex lg:flex-1">
              <a href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Krono</span>
                <img alt="" src={siteLogo} className="h-6 w-auto rounded-md" />
              </a>
            </div>
            <div class="flex lg:hidden">
              <button
                type="button"
                class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span class="sr-only">Open main menu</span>
                <svg
                  class="size-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                  data-slot="icon"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              </button>
            </div>
            <div class="hidden lg:flex lg:gap-x-12">
              {/* <a
                href="/krono-avtal"
                class="text-sm/6 font-semibold text-gray-900"
                href="#tier-enterprise"
              >
                Krono Avtal
              </a> */}
              <a
                href="/integritetspolicy"
                class="text-sm/6 font-semibold text-gray-900"
              >
                Integritetspolicy
              </a>
              <a
                href="anvandarvillkor"
                class="text-sm/6 font-semibold text-gray-900"
              >
                Användarvillkor
              </a>
            </div>
          </nav>

          <Dialog
            open={mobileMenuOpen}
            onClose={setMobileMenuOpen}
            className="lg:hidden"
          >
            <div className="fixed inset-0 z-50" />
            <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <button
                  type="button"
                  onClick={() => setMobileMenuOpen(false)}
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon aria-hidden="true" className="size-6" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </DialogPanel>
          </Dialog>
        </header>

        <div className="relative isolate px-6 pt-14 lg:px-8">
          <div
            aria-hidden="true"
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          >
            <div
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            />
          </div>
          <div className="flex flex-col sm:flex-row  justify-evenly items-center">
            <div className="text-center max-w-3xl">
              <h1 className="text-balance md:text-8xl text-5xl font-bold  text-gray-900 sm:text-7xl font-poppins letter tracking-widest">
                KRONOS
              </h1>
              <p className="mt-8 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8 font-poppins break-words">
                Upptäck Sveriges nya marknadsplats för klockor! Köp och sälj
                alla sorters klockor enkelt och tryggt, direkt från din mobil.
                Perfekt för alla med ett klockintresse!
              </p>
              <div className="mt-10 flex items-normal justify-center gap-x-6">
                <a href="https://apps.apple.com/se/app/chronos/id6472299482">
                  <img src={applestore} alt="" className="h-12" />
                </a>

                <a href="https://play.google.com/store/apps/details?id=com.stagem.Chronos">
                  <img src={googleplay} alt="" className="h-12" />
                </a>
              </div>
            </div>
            <div className="">
              <img
                src={login}
                alt="Logo"
                className="h-[750px] sm:h-[800px] mt-6"
              />
            </div>
          </div>

          <div
            aria-hidden="true"
            className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          >
            <div
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
              className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            />
          </div>
        </div>
      </div>

      <div className="bg-gray-50 py-24 sm:py-32">
        <div className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
          <h2 className="text-center text-2xl font-semibold text-indigo-600 font-poppins">
            Klockentusiast eller samlare
          </h2>
          <p className="mx-auto mt-2 max-w-2xl text-balance text-center text-4xl font-semibold tracking-tight text-gray-950 sm:text-5xl font-poppins">
            Din kompletta lösning för att köpa och sälja klockor
          </p>
          <div className=" grid gap-8 sm:mt-16 lg:grid-cols-3 lg:grid-rows-2 bg-white rounded-xl p-4">
            <div className="relative lg:row-span-2 bg-white rounded-lg">
              <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-l-[calc(2rem+1px)]">
                <div className="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
                  <p className="mt-2 text-lg font-medium tracking-tight text-gray-950 max-lg:text-center font-poppins">
                    Upptäck klockor på ditt sätt
                  </p>
                  <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center my-4 font-poppins">
                    Med kraftfull sök- och filterfunktioner hittar du exakt den
                    klocka du letar efter – snabbt och enkelt!
                  </p>
                </div>
                <div className="relative min-h-[30rem] w-full grow [container-type:inline-size] max-lg:mx-auto max-lg:max-w-sm">
                  <div className="my-0">
                    <img
                      className="size-full object-cover object-top"
                      src={start}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="relative lg:row-span-2">
              <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-l-[calc(2rem+1px)]">
                <div className="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
                  <p className="mt-2 text-lg font-medium tracking-tight text-gray-950 max-lg:text-center font-poppins">
                    Håll kontakten direkt i appen
                  </p>
                  <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center my-4 font-poppins">
                    Vår smidiga chattfunktion gör det enkelt att förhandla,
                    ställa frågor och säkra en affär. All kommunikation sker
                    tryggt på ett ställe, utan att behöva lämna appen.
                  </p>
                </div>
                <div className="relative min-h-[30rem] w-full grow [container-type:inline-size] max-lg:mx-auto max-lg:max-w-sm">
                  <div className="my-3">
                    <img
                      className="size-full object-cover object-top"
                      src={chat}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="relative lg:row-span-2">
              <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-l-[calc(2rem+1px)]">
                <div className="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
                  <p className="mt-2 text-lg font-medium tracking-tight text-gray-950 max-lg:text-center font-poppins">
                    Visa upp din stil och hitta inspiration
                  </p>
                  <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center my-4 font-poppins">
                    Inspirera och bli inspirerad i ett community av passionerade
                    klockälskare.
                  </p>
                </div>
                <div className="relative min-h-[30rem] w-full grow [container-type:inline-size] max-lg:mx-auto max-lg:max-w-sm">
                  <div className="my-2">
                    <img
                      className="size-full object-cover object-top"
                      src={kronogram}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 py-24 sm:py-32">
        <div className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
          <h2 className="text-center text-2xl font-semibold font-poppins">
            <div className="flex justify-center align-middle items-center font-poppins font-semibold ">
              <img src={cert} alt="" className="h-20" />{" "}
              <p className="text-2xl">Kronos Certified</p>
            </div>
          </h2>
          <p className="mx-auto mt-3 mb-3 max-w-2xl text-balance text-center text-4xl font-semibold tracking-tight text-gray-950 sm:text-5xl font-poppins">
            Kronos Certified by UrBrev
          </p>
          <div className=" col gap-8 sm:mt-16 lg:grid-cols-1 lg:grid-rows-1 bg-white rounded-xl p-4">
            <div className="relative lg:row-span-2">
              <div className="relative flex h-[50%] flex-col rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-l-[calc(2rem+1px)]">
                <div className="px-2 pb-3 pt-2 sm:px-5 sm:pb-0 sm:pt-5">
                  <p className="mt-2 text-lg font-medium tracking-tight text-gray-950 max-lg:text-center font-poppins">
                    Garanterad trygghet för köpare och säljare
                  </p>
                  <p className="mt-2  text-sm/6 text-gray-600 max-lg:text-center my-4 font-poppins">
                    Exklusiva klockor är skapade för att hålla i generationer!
                    Att köpa en klocka som är "Kronos Certified" är ett
                    fantastiskt sätt att förverkliga drömklockan. Alla klockor
                    som certifieras av oss genomgår noggranna tester och
                    kontroller för att garantera att klockan stämmer överens med
                    beskrivningen i annonsen.UrBrev utför endast Kronos
                    certifieringar på armbandsur som annonseras via Kronos App,
                    vilket skapar ytterligare en trygghet för dig som köpare.
                  </p>
                </div>
                <div className="relative min-h-[30rem] w-full grow [container-type:inline-size] max-lg:mx-auto max-lg:max-w-sm">
                  <div className="my-2">
                    <img
                      className="size-full object-cover object-top md:w-[60%] m-auto"
                      src={certified}
                      alt=""
                    />
                  </div>
                </div>

                <div className="col justify-center align-middle items-center px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
                  <p className="my-2 text-lg font-medium tracking-tight text-gray-950 max-lg:text-center font-poppins">
                    Vad kontrolleras?
                  </p>
                  <div className="grid md:grid-cols-3 ">
                    <div className="flex flex-row justify-start align-middle items-center gap-2 p-2 rounded-md">
                      <FeatherIcon icon="check-circle" size={20} />
                      <span>Verifiering av klockans äkthet</span>
                    </div>
                    <div className="flex flex-row justify-start align-middle items-center gap-2  p-2 rounded-md">
                      <FeatherIcon icon="check-circle" size={20} />
                      <span>Stöldkontroll</span>
                    </div>

                    <div className="flex flex-row justify-start align-middle items-center gap-2  p-2 rounded-md">
                      <FeatherIcon icon="check-circle" size={20} />
                      <span>Gångkontroll</span>
                    </div>

                    <div className="flex flex-row justify-start align-middle items-center gap-2  p-2 rounded-md">
                      <FeatherIcon icon="check-circle" size={20} />
                      <span>Kontroll av bandstift</span>
                    </div>

                    <div className="flex flex-row justify-start align-middle items-center gap-2  p-2 rounded-md">
                      <FeatherIcon icon="check-circle" size={20} />
                      <span>Infettning av boettpackningar</span>
                    </div>

                    <div className="flex flex-row justify-start align-middle items-center gap-2  p-2 rounded-md">
                      <FeatherIcon icon="check-circle" size={20} />
                      <span>Täthetskontoll</span>
                    </div>

                    <div className="flex flex-row justify-start align-middle items-center gap-2  p-2 rounded-md">
                      <FeatherIcon icon="check-circle" size={20} />
                      <span>Kontroll av amplitud i 4 positioner</span>
                    </div>

                    <div className="flex flex-row justify-start align-middle items-center gap-2  p-2 rounded-md w-fit">
                      <FeatherIcon icon="check-circle" size={20} />
                      <span>Kontroll av objektbeskrivning i Kronos</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32 rel">
        <img src={gyrate} alt="" className="absolute top-38 right-0" />
        <div
          class="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
          aria-hidden="true"
        >
          <div
            class="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          ></div>
        </div>
        <div
          class="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
          aria-hidden="true"
        >
          <div
            class="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          ></div>
        </div>
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
          <div class="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-balance md:text-6xl text-white text-5xl font-bold  sm:text-7xl font-poppins letter tracking-widest">
              KRONOS
            </h2>

            <p class="mt-8 text-pretty text-lg font-medium text-gray-300 sm:text-xl/8 font-poppins">
              Kronos är appen där du kan handla allt från prisvärda till
              exklusiva klockor direkt från andra entusiaster.
            </p>
            <p class="mt-8 text-pretty text-lg font-medium text-gray-300 sm:text-xl/8 font-poppins">
              Ladda ner Kronos appen och upptäck Sveriges nya marknadsplats för
              klockor!
            </p>
          </div>
          <div class="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
            {/* <div class="grid grid-cols-1 gap-x-8 gap-y-6 text-base/7 font-semibold text-white sm:grid-cols-2 md:flex lg:gap-x-10">
              <a href="#">
                Open roles <span aria-hidden="true">&rarr;</span>
              </a>
              <a href="#">
                Internship program <span aria-hidden="true">&rarr;</span>
              </a>
              <a href="#">
                Our values <span aria-hidden="true">&rarr;</span>
              </a>
              <a href="#">
                Meet our leadership <span aria-hidden="true">&rarr;</span>
              </a>
            </div> */}
            {/* <dl class="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
              <div class="flex flex-col-reverse gap-1">
                <dt class="text-base/7 text-gray-300">Offices worldwide</dt>
                <dd class="text-4xl font-semibold tracking-tight text-white">
                  12
                </dd>
              </div>
              <div class="flex flex-col-reverse gap-1">
                <dt class="text-base/7 text-gray-300">Full-time colleagues</dt>
                <dd class="text-4xl font-semibold tracking-tight text-white">
                  300+
                </dd>
              </div>
              <div class="flex flex-col-reverse gap-1">
                <dt class="text-base/7 text-gray-300">Hours per week</dt>
                <dd class="text-4xl font-semibold tracking-tight text-white">
                  40
                </dd>
              </div>
              <div class="flex flex-col-reverse gap-1">
                <dt class="text-base/7 text-gray-300">Paid time off</dt>
                <dd class="text-4xl font-semibold tracking-tight text-white">
                  Unlimited
                </dd>
              </div>
            </dl> */}
          </div>
        </div>
      </div>

      {/* <div class="overflow-hidden bg-white py-24 sm:py-32">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
          <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div class="lg:pr-8 lg:pt-4">
              <div class="lg:max-w-lg">
                <h2 class="text-base/7 font-semibold text-indigo-600">
                  Visa upp din stil och hitta inspiration
                </h2>
                <p class="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                  A better workflow
                </p>
                <p class="mt-6 text-lg/8 text-gray-600">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Maiores impedit perferendis suscipit eaque, iste dolor
                  cupiditate blanditiis ratione.
                </p>
                <dl class="mt-10 max-w-xl space-y-8 text-base/7 text-gray-600 lg:max-w-none">
                  <div class="relative pl-9">
                    <dt class="inline font-semibold text-gray-900">
                      <svg
                        class="absolute left-1 top-1 size-5 text-indigo-600"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                        data-slot="icon"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.5 17a4.5 4.5 0 0 1-1.44-8.765 4.5 4.5 0 0 1 8.302-3.046 3.5 3.5 0 0 1 4.504 4.272A4 4 0 0 1 15 17H5.5Zm3.75-2.75a.75.75 0 0 0 1.5 0V9.66l1.95 2.1a.75.75 0 1 0 1.1-1.02l-3.25-3.5a.75.75 0 0 0-1.1 0l-3.25 3.5a.75.75 0 1 0 1.1 1.02l1.95-2.1v4.59Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      Push to deploy.
                    </dt>
                    <dd class="inline">
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Maiores impedit perferendis suscipit eaque, iste dolor
                      cupiditate blanditiis ratione.
                    </dd>
                  </div>
                  <div class="relative pl-9">
                    <dt class="inline font-semibold text-gray-900">
                      <svg
                        class="absolute left-1 top-1 size-5 text-indigo-600"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                        data-slot="icon"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 1a4.5 4.5 0 0 0-4.5 4.5V9H5a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2h-.5V5.5A4.5 4.5 0 0 0 10 1Zm3 8V5.5a3 3 0 1 0-6 0V9h6Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      SSL certificates.
                    </dt>
                    <dd class="inline">
                      Anim aute id magna aliqua ad ad non deserunt sunt. Qui
                      irure qui lorem cupidatat commodo.
                    </dd>
                  </div>
                  <div class="relative pl-9">
                    <dt class="inline font-semibold text-gray-900">
                      <svg
                        class="absolute left-1 top-1 size-5 text-indigo-600"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                        data-slot="icon"
                      >
                        <path d="M4.632 3.533A2 2 0 0 1 6.577 2h6.846a2 2 0 0 1 1.945 1.533l1.976 8.234A3.489 3.489 0 0 0 16 11.5H4c-.476 0-.93.095-1.344.267l1.976-8.234Z" />
                        <path
                          fill-rule="evenodd"
                          d="M4 13a2 2 0 1 0 0 4h12a2 2 0 1 0 0-4H4Zm11.24 2a.75.75 0 0 1 .75-.75H16a.75.75 0 0 1 .75.75v.01a.75.75 0 0 1-.75.75h-.01a.75.75 0 0 1-.75-.75V15Zm-2.25-.75a.75.75 0 0 0-.75.75v.01c0 .414.336.75.75.75H13a.75.75 0 0 0 .75-.75V15a.75.75 0 0 0-.75-.75h-.01Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      Database backups.
                    </dt>
                    <dd class="inline">
                      Ac tincidunt sapien vehicula erat auctor pellentesque
                      rhoncus. Et magna sit morbi lobortis.
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            <img
              src="https://tailwindui.com/plus/img/component-images/dark-project-app-screenshot.png"
              alt="Product screenshot"
              class="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
              width="2432"
              height="1442"
            />
          </div>
        </div>
      </div> */}

      {/* <div class="relative isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div
          class="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl"
          aria-hidden="true"
        >
          <div
            class="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          ></div>
        </div>
        <div class="mx-auto max-w-4xl text-center">
          <h2 class="text-2xl font-semibold text-indigo-600 font-poppins">
            Krono avtal
          </h2>
          <p class="mt-2 text-balance text-2xl font-semibold tracking-tight text-gray-900 sm:text-6xl sm:leading-tight font-poppins">
            Extra trygghet och säkerhet för både köpare och säljare
          </p>
        </div>
        <p class="mx-auto mt-6 max-w-2xl text-pretty text-center text-lg font-medium text-gray-600 sm:text-xl/8 font-poppins">
          Gör din klockaffär smidigare och säkrare med Krono-avtalet.
        </p>
        <p class="mx-auto  max-w-2xl text-pretty text-center text-xl font-medium text-gray-600 sm:text-xl/8 font-poppins">
          Med vårt avtal skyddas både köpare och säljare, vilket skapar
          förtroende och trygghet i varje steg av processen.
        </p>
        <div
          class="flex flex-row justify-center mt-12 items-center align-middle gap-y-6"
          id="tier-enterprise"
        >
          <div class="relative rounded-3xl bg-gray-900 p-8 shadow-2xl ring-1 ring-gray-900/10 sm:p-10">
            <h3
              id="tier-enterprise"
              class="text-4xl font-semibold text-indigo-400 font-poppins"
            >
              Krono avtal
            </h3>
            <p class="mt-4 flex items-baseline gap-x-2">
              <span class="text-5xl font-semibold tracking-tight text-white font-poppins">
                499kr
              </span>
              <span class="text-base text-gray-400 font-poppins">
                / försäljing
              </span>
            </p>
            <p class="mt-6 text-base/7 text-gray-300 font-poppins">
              Säkra din affär och handla med lugn i sinnet – välj Krono-avtalet!
            </p>
            <p class="mt-2 text-base/7 text-gray-300 underline font-poppins">
              Lägg till Krono avtal!
            </p>
            <ul class="mt-8 space-y-3 text-sm/6 text-gray-300 sm:mt-10 font-poppins">
              <li class="flex gap-x-3">
                <svg
                  class="h-6 w-5 flex-none text-indigo-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  data-slot="icon"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Köp och sälj med trygghet
              </li>
              <li class="flex gap-x-3 font-poppins">
                <svg
                  class="h-6 w-5 flex-none text-indigo-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  data-slot="icon"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Skydd för både köpare och säljare
              </li>
              <li class="flex gap-x-3 font-poppins">
                <svg
                  class="h-6 w-5 flex-none text-indigo-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  data-slot="icon"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Motverka bedrägerier effektivt
              </li>
              <li class="flex gap-x-3 font-poppins">
                <svg
                  class="h-6 w-5 flex-none text-indigo-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  data-slot="icon"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Enkel signering med BankID
              </li>
              <li class="flex gap-x-3 font-poppins">
                <svg
                  class="h-6 w-5 flex-none text-indigo-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  data-slot="icon"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Krono-avtal skapas direkt i appen
              </li>
              <li class="flex gap-x-3 font-poppins">
                <svg
                  class="h-6 w-5 flex-none text-indigo-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  data-slot="icon"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Säkerhet i varje klockaffär
              </li>
            </ul>
            <a class="font-poppins mt-8 block rounded-md bg-indigo-500 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 sm:mt-10">
              Läs mer i appen
            </a>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default StartPage;
